body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto','Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: #315b34;
  text-decoration: none;
}

a:hover {
  color: #003e1f;
  font-weight: bold;
}

button {
  background-color: #315B34;
  border: 1px solid #003E1F;
  border-radius: 30px;
  color: white;
  padding: 10px 25px;
  margin: 4px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

button:hover {
  background-color: #315b34;
  color: white;
}

h1, h2, h3 {
  font-family: "Roboto", "Open-Sans";
  font-weight: 200;
}

p {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  font-family: "Roboto", "Open-Sans";
}

section {
  margin: 3rem auto;
  max-width: 500px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 3rem;
  text-align: center;
}

section h1 {
  text-align: center;
}

* {
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  form input,
  form select,
  form textarea {
    width: 300px;
    height: 32px;
  }
  section {
    max-width: 400px;
  }
}
