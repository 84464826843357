.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    flex: 1;
    border-bottom: 1px solid #eaeaea;
    align-items: center;
    justify-content: space-between;
    background-color: #003E1F;
    padding: 0 5%;
  }

  .button {
    background-color: #003E1F;
    border: 1px solid #FF8D01;
    border-radius: 30px;
    color: white;
    padding: 10px 25px;
    margin: 4px 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
  }

  .header img {
    height: 50px;
    width: 50px;
  }

  .header a {
    text-decoration: none;
    font-size: 1.0rem;
    font-weight: 300;
    color: #FF8D01;
    padding: 1rem 1.5rem;
  }
  
  .header a:hover,
  .header a:active,
  .header a.active {
    color: #FF8D01;
    font-weight: bold;
  }

  .auth {
      float: right;
  }

  @media only screen and (max-width: 600px) {
    .header button {
      font-size: 12px;
      padding: 8px 15px;
      margin: 2px 3px;
    }
    .header img {
      height: 45;
      width: 45;
    }
  }